import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { AgGridReact, ChangeDetectionStrategyType } from 'ag-grid-react';
import { Grid } from '@material-ui/core';
import {
    AuthContext,
    PacingContext,
    PacingAnalysisContext,
    TargetAllocationContext,
} from 'context';
import {
    CommitmentScheduleColumnDefinitions,
    FrameworkComponents,
    ReadOnlyCommitmentScheduleColumnDefinitions,
    ReadOnlyFrameworkComponents,
    AdvancedOptimizationModal,
    TotalsColumnDefinitions,
} from './components';

const TotalAssumptionTable = ({
    commitmentSchedule,
    startYear,
    endYear,
    fiscalYearPlusOne,
    getCommitScheduleStyling
}) => {

    const topApi = useRef();
    const growthApi = useRef();
    const gridApi = useRef();
    
    const totalsOptions = { alignedGrids: [] };
    const growthOptions = { alignedGrids: [] };
    const commitOptions = { alignedGrids: [] };
    const portAumOptions = { alignedGrids: [] };
    
    totalsOptions.alignedGrids.push(growthOptions);
    growthOptions.alignedGrids.push(totalsOptions);
    commitOptions.alignedGrids.push(portAumOptions);
    commitOptions.overlayLoadingTemplate =
        '<span>Loading Commitment Schedule...</span>';
    commitOptions.overlayNoRowsTemplate = '<span></span>';
    portAumOptions.alignedGrids.push(commitOptions);
    
    const [totalGrowthPercentOptions] = useState(growthOptions);
    const [totalPortfolioOptions] = useState(totalsOptions);
    const [commitmentScheduleOptions] = useState(commitOptions);
    const [apiReady, setApiReady] = useState({
        top: false,
        growth: false,
        grid: false,
        bottom: false,
    });
    const [enableResetCommitments, setEnableResetCommitments] = useState(false);
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);

    const {
        growthType,
        pacingParameters: {
            commitmentScheduleOptimizer,
            multipleGrowthPercents,
        },
        portfolioContributions,
        setPortfolioContributions,
        setPacingParametersKeyValue,
    } = useContext(PacingAnalysisContext);

    const {
        pacingClientReadOnly,
        pacingClientReadOnlyAndSpiClientId,
    } = useContext(PacingContext);

    const {
        targetAllocation: { targetExposureType },
    } = useContext(TargetAllocationContext);

    const { userSpiClientId, userIsAdmin } = useContext(AuthContext);

    const defaultColDef = {
        resizable: true,
        sortable: false,
        cellStyle: (params) =>
            getCommitScheduleStyling(params, portfolioContributions),
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        cellEditorParams: (params) => {
            return {
                field: params.field,
                updateValue,
            };
        },
        filter: false,
        editable: !!(!pacingClientReadOnly && !userSpiClientId),
        suppressMenu: true,
        suppressDragLeaveHidesColumns: true,
        suppressMovable: true,
        flex: 1,
    };
    const frameworkComponents = pacingClientReadOnlyAndSpiClientId()
        ? ReadOnlyFrameworkComponents()
        : FrameworkComponents();

    const onGrowthReady = (params) => {
        growthApi.current = params.api;
        setApiReady((prevState) => ({
            ...prevState,
            growth: true,
        }));
    };

    const onTopReady = (params) => {
        topApi.current = params.api;
        setApiReady((prevState) => ({
            ...prevState,
            top: true,
        }));
    };

    /**
     * Helper function for creating portfolioContributions and
     * multipleGrowthPercents objects on initial component mounting.
     *
     * @param {*} objectToFormat
     */
    const formatDataObject = (objectToFormat) => {
        const newObject = {};
        const dataToFormat =
            objectToFormat === 'portfolioContributions'
                ? portfolioContributions
                : multipleGrowthPercents;

        // TODO: keep userEdited key
        if (Object.keys(dataToFormat).length === 0) {
            for (let i = startYear; i <= endYear; i++) {
                const year = fiscalYearPlusOne ? i + 1 : i;
                if (year <= endYear) {
                    newObject[year] = 0;
                }
            }
        } else {
            for (let i = startYear; i <= endYear; i++) {
                const year = fiscalYearPlusOne ? i + 1 : i;
                if (year <= endYear) {
                    if (
                        Object.prototype.hasOwnProperty.call(dataToFormat, year)
                    ) {
                        newObject[year] = dataToFormat[year];
                    } else {
                        newObject[year] = 0;
                    }
                }
            }
        }

        if (
            Object.keys(dataToFormat).length !== Object.keys(newObject).length
        ) {
            if (objectToFormat === 'portfolioContributions') {
                newObject.strategy = 'Total Portfolio Net Cash Flows';
                setPortfolioContributions(newObject);
            } else if (objectToFormat === 'multipleGrowthPercents') {
                newObject.strategy = 'Total Portfolio Growth p.a. (%)';
                setPacingParametersKeyValue(
                    'multipleGrowthPercents',
                    newObject
                );
            }
        }
    };

    // Update portfolio contribution or commitment schedule
    // when a value has changed
    const updateValue = (value, field, data) => {
        let valueCopy = value;

        if(data[field] === value) {
            return data;
        }

        if (data.strategy === 'Total Portfolio Net Cash Flows') {
            const newPortContrib = portfolioContributions;
            newPortContrib[field] = valueCopy;
            if (
                Array.isArray(newPortContrib?.userEdited) &&
                !newPortContrib?.userEdited?.includes(field)
            ) {
                newPortContrib.userEdited.push(field);
            } else if (!Array.isArray(newPortContrib?.userEdited)) {
                newPortContrib.userEdited = [field];
            }
            setPortfolioContributions(newPortContrib);
            totalPortfolioOptions.api.refreshCells();
        } else if (data.strategy === 'Total Portfolio Growth p.a. (%)') {
            if (valueCopy > 100) {
                valueCopy = 100;
            }
            if (valueCopy < -98) {
                valueCopy = -98;
            }
            multipleGrowthPercents[field] = valueCopy;
            if (
                Array.isArray(multipleGrowthPercents?.userEdited) &&
                !multipleGrowthPercents?.userEdited?.includes(field)
            ) {
                multipleGrowthPercents.userEdited.push(field);
            } else if (!Array.isArray(multipleGrowthPercents?.userEdited)) {
                multipleGrowthPercents.userEdited = [field];
            }

            setPacingParametersKeyValue(
                'multipleGrowthPercents',
                multipleGrowthPercents
            );
            totalGrowthPercentOptions.api.refreshCells();
        } else {
            const newCommitmentSchedule = commitmentSchedule.map((row) => {
                const holder = row;
                if (holder.strategy === data.strategy) {
                    holder[field] = valueCopy;
                    if (
                        Array.isArray(holder?.userEdited) &&
                        !holder?.userEdited?.includes(field)
                    ) {
                        holder.userEdited.push(field);
                    } else if (!Array.isArray(holder?.userEdited)) {
                        holder.userEdited = [field];
                    }
                }

                return holder;
            });
            setPacingParametersKeyValue(
                'commitmentSchedule',
                newCommitmentSchedule
            );
            setEnableResetCommitments(true)
        }
    };

    const updateColumnDefs = useCallback(() => {
        const newColumnDefs = pacingClientReadOnlyAndSpiClientId()
            ? ReadOnlyCommitmentScheduleColumnDefinitions(
                  startYear,
                  endYear,
                  fiscalYearPlusOne
              )
            : CommitmentScheduleColumnDefinitions(
                  updateValue,
                  startYear,
                  endYear,
                  fiscalYearPlusOne,
                  toggleLock,
                  commitmentScheduleOptimizer,
                  handleValueClick,
                  targetExposureType
              );

        const newTotalsColumnDefs = TotalsColumnDefinitions(
            updateValue,
            startYear,
            endYear,
            fiscalYearPlusOne,
            toggleLock,
            commitmentScheduleOptimizer,
            handleValueClick,
            targetExposureType
        );

        if (
            topApi.current &&
            growthApi.current
        ) {
            topApi.current.setColumnDefs(newTotalsColumnDefs);
            growthApi.current.setColumnDefs(newTotalsColumnDefs);
        }
    }, [
        startYear,
        endYear,
        fiscalYearPlusOne,
        commitmentScheduleOptimizer,
        commitmentSchedule,
        growthType,
    ]);

    const toggleLock = (field, strategy) => {
        const newOptimizer = [...commitmentScheduleOptimizer].map((row) => {
            const updatedRow = { ...row };

            Object.keys(row).forEach((key) => {
                if (updatedRow.strategy === strategy && key === field) {
                    updatedRow[field] = row[field] === 0 ? 1 : 0;
                }
            });

            return updatedRow;
        });

        setPacingParametersKeyValue(
            'commitmentScheduleOptimizer',
            newOptimizer
        );
    };

    // Add up totals of all strategies for each year between start and end year
    const setTotals = () => {
        const yearArrays = { strategy: 'Total' };
        for (let i = startYear; i <= endYear; i++) {
            const year = fiscalYearPlusOne ? i + 1 : i;
            if (year <= endYear) {
                yearArrays[year] = 0;
            }
        }
        commitmentSchedule.forEach((row) => {
            for (let i = startYear; i <= endYear; i++) {
                const year = fiscalYearPlusOne ? i + 1 : i;
                if (year <= endYear && row[year]) {
                    yearArrays[year] += parseFloat(row[year]);
                }
            }
        });

        Object.values(yearArrays).slice(0,-1).some(el => el > 0) ? setEnableResetCommitments(true) : setEnableResetCommitments(false)

        setPinnedBottomRowData([yearArrays]);
    };

    const handleValueClick = (colDef, rowIndex, data) => {
        if (
            data.strategy !== 'Total Portfolio Net Cash Flows' &&
            data.strategy !== 'Total Portfolio Growth p.a. (%)' &&
            data.strategy !== 'Portfolio AuM'
        ) {
            const foundMatchingCol = commitmentScheduleOptions.columnApi
                .getAllGridColumns()
                .find((column) => column.colId.includes(colDef.field));
            gridApi.current.startEditingCell({
                rowIndex,
                colKey: foundMatchingCol.colId,
            });
        }
    };

    useEffect(() => {
        if (
            startYear.toString().length === 4 &&
            endYear.toString().length === 4
        ) {
            formatDataObject('portfolioContributions');
            formatDataObject('multipleGrowthPercents');
        }
    }, []);

    /**
     * Reformats multipleGrowthPercents when endYear is altered
     */
    useEffect(() => {
        const lastYearInGrowthPercent = parseInt(
            Object.keys(multipleGrowthPercents).slice(-2)[0],
            10
        );

        if (endYear !== lastYearInGrowthPercent) {
            formatDataObject('multipleGrowthPercents');
        }
    }, [endYear]);

    // initial setup for the three grids once it loads
    useEffect(() => {
        if (
            apiReady.top &&
            apiReady.growth
        ) {
            updateColumnDefs();
        }
    }, [apiReady.top, apiReady.growth]);

    useEffect(() => {
        updateColumnDefs();
    }, [updateColumnDefs]);

    useEffect(() => {
        if (gridApi.current) {
            gridApi.current.setRowData(commitmentSchedule);
        }
        setTotals();
    }, [commitmentSchedule]);

    return (
        <Grid container md={12}>
            <Grid item md={12}
                style={{
                    display: growthType === 'multiple' ? 'block' : 'none',
                }}
            >
                <div
                    className='ag-theme-balham'
                    style={{
                        position: 'relative',
                        height: '56px',
                        width: '100%',
                        marginBottom: '24px',
                    }}
                >
                    <AgGridReact
                        animateRows
                        defaultColDef={defaultColDef}
                        components={frameworkComponents}
                        gridOptions={totalGrowthPercentOptions}
                        headerHeight={growthType === 'multiple' ? 25 : 0}
                        onGridReady={onGrowthReady}
                        rowData={[multipleGrowthPercents]}
                        rowDataChangeDetectionStrategy={
                            ChangeDetectionStrategyType.IdentityCheck
                        }
                        singleClickEdit={true}
                        stopEditingWhenCellsLoseFocus={true}
                        suppressContextMenu
                        suppressHorizontalScroll
                    />
                </div>
            </Grid>
            <Grid item md={12}>
                <div
                    className='ag-theme-balham'
                    style={{
                        position: 'relative',
                        height: '56px',
                        width: '100%',
                    }}
                >
                    <AgGridReact
                        animateRows
                        defaultColDef={defaultColDef}
                        components={frameworkComponents}
                        gridOptions={totalPortfolioOptions}
                        headerHeight={growthType === 'multiple' ? 25 : 25}
                        onGridReady={onTopReady}
                        rowData={[{
                            strategy: 'Total Portfolio Net Cash Flows',
                            ...portfolioContributions
                        }]}
                        rowDataChangeDetectionStrategy={
                            ChangeDetectionStrategyType.IdentityCheck
                        }
                        singleClickEdit={true}
                        stopEditingWhenCellsLoseFocus={true}
                        suppressContextMenu
                    />
                </div>
            </Grid>
        </Grid>
    )
}

export default TotalAssumptionTable