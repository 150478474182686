import {
    postLogisticFutureCashFlows,
    postLogisticExistingCashFlows,
    postYaleFutureCashFlows,
    postYaleExistingCashFlows,
    getHistoricalCashFlows,
} from 'api';
import { getCurrQuarterEndDate } from 'utils';
import getVintageDate from '../getVintageDate';

const calcFundParams = (currentSliders, row, currentParams, reportDate) => {
    // import function switch case for quarter
    const vintageDate = getVintageDate(
        currentSliders.commitQtr,
        currentSliders.closingYear
    );

    const params = currentParams;

    // calculate istart
    const parts = reportDate.split('-');
    const date1 = new Date(parts[0], parts[1] - 1, parts[2]); // reportDate
    const date2 = new Date(vintageDate);
    const diffTime = date1.getTime() - date2.getTime() || 0;
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    // calculate qtrFrac (quarter fraction)
    const currQtrEnd = getCurrQuarterEndDate();
    const currDiffTime = Math.abs(currQtrEnd.getTime() - date1.getTime()) || 0;
    const currDiffDays = Math.round(currDiffTime / (1000 * 60 * 60 * 24));

    // add values to parameters
    params.istart = Math.round((diffDays / 365.25) * 4) >= 1 ? Math.round((diffDays / 365.25) * 4) : 0; //index starts at 0
    params.qtrfrac = currDiffDays / 91.3 || 1; // 91.3 = num of days in a quarter
    params.unfunded_data = [
        parseFloat(row.Unfunded),
        parseFloat(row.Commitment) || parseFloat(row.Contributions),
    ];
    params.nav_data = [
        parseFloat(row.AdjNAV),
        parseFloat(row.Commitment) || parseFloat(row.Contributions),
    ];
    params.contrib_data = [
        parseFloat(row.Contributions) || 0,
        parseFloat(row.Commitment) || parseFloat(row.Contributions),
    ];
    params.distrib_data = [
        parseFloat(row.Distributions) || 0,
        parseFloat(row.Commitment) || parseFloat(row.Contributions),
    ];

    return params;
};

const calculateCoinvestFlagValue = (currentSliders, strategyParameters) => {
    let coinvestFlagValue = 0;

    if (
        currentSliders.coinvestFlag === null ||
        typeof currentSliders.coinvestFlag === 'undefined'
    ) {
        const foundStrat = [...strategyParameters].find(
            ({ strategy, model }) =>
                strategy === currentSliders.strategy &&
                model === currentSliders.model
        );

        coinvestFlagValue = foundStrat.coinvestFlag || 0;
    } else {
        coinvestFlagValue = currentSliders.coinvestFlag || 0;
    }

    return coinvestFlagValue;
};

const calculateTgrid = (numYrsFromVinToCurrDate, currentSliders) => {
    const tgridEnd =
        parseFloat(numYrsFromVinToCurrDate) >
        parseFloat(currentSliders.fundLife)
            ? numYrsFromVinToCurrDate + 1
            : currentSliders.fundLife + 1;

    return [0, tgridEnd, tgridEnd * 4 + 1];
};

const gatherDefaultParameters = (currentSliders, tgrid, strategyParameters) => {
    // find default strategy psets
    const defaultSliderData = [...strategyParameters].find(
        ({ strategy, model, case: scenario }) =>
            strategy === currentSliders.strategy &&
            model === currentSliders.model &&
            scenario === 'Base'
    );

    const psetForDefault = [
        parseFloat(defaultSliderData.paidIn),
        defaultSliderData.irr === '-' ? 0 : parseFloat(defaultSliderData.irr), // to handle when user has only entered '-'
        parseFloat(defaultSliderData.yld),
        parseFloat(defaultSliderData.fundLife),
        parseFloat(defaultSliderData.bow),
        parseFloat(defaultSliderData.rc1),
        parseFloat(defaultSliderData.rc2),
        parseFloat(defaultSliderData.rc3),
        defaultSliderData.rc4 === '' ? 0 : parseFloat(defaultSliderData.rc4),
        defaultSliderData.rc5 === '' ? 0 : parseFloat(defaultSliderData.rc5),
        defaultSliderData.coinvestFlag || 0,
    ];

    return {
        pset: psetForDefault,
        tgrid,
    };
};

const gatherParameters = (currentSliders, tgrid, coinvestFlagValue) => {
    const pset = [
        parseFloat(currentSliders.paidIn),
        currentSliders.irr === '-' ? 0 : parseFloat(currentSliders.irr), // to handle when user has only entered '-'
        parseFloat(currentSliders.yld),
        parseFloat(currentSliders.fundLife),
        parseFloat(currentSliders.bow),
        parseFloat(currentSliders.rc1),
        parseFloat(currentSliders.rc2),
        parseFloat(currentSliders.rc3),
        currentSliders.rc4 === '' ? 0 : parseFloat(currentSliders.rc4),
        currentSliders.rc5 === '' ? 0 : parseFloat(currentSliders.rc5),
        coinvestFlagValue || 0,
    ];

    return {
        pset,
        tgrid,
        openflag: 'Closed',
    };
};

const getEndpoints = (currentSliders) => {
    const future =
        currentSliders.model === 'Yale'
            ? postYaleFutureCashFlows
            : postLogisticFutureCashFlows;
    const existing =
        currentSliders.model === 'Yale'
            ? postYaleExistingCashFlows
            : postLogisticExistingCashFlows;

    return {
        future,
        existing,
    };
};

const formatPset = (pset) => {
    return {
        ...pset,
        paidIn: pset.paidIn / 100,
        irr: pset.irr / 100,
        yld: pset.yld / 100,
        fundLife: pset.fundLife,
        bow: pset.bow,
        coinvestFlag: pset.coinvestFlag,
        rc1: pset.rc1 / 100,
        rc2: pset.rc2 / 100,
        rc3: pset.rc3 / 100,
        rc4: pset.rc4 / 100,
        rc5: pset.rc5 / 100,
    };
};

const getSavedPset = (
    currentSliders,
    stateProps,
    scenario,
    fundSliderValues,
    sliderValues,
    underwritingDataset
) => {
    const { commitmentType, fundId } = stateProps;

    if (commitmentType === 'new') {
        const foundSavedPsets = underwritingDataset.filter(
            ({ strategy }) => currentSliders.strategy === strategy
        );

        if (foundSavedPsets.length === 3) {
            const foundPset = [...foundSavedPsets].find(
                (row) => row.scenario === scenario
            );

            // TODO: look into why this if statement is needed.  Breaks without it
            if (foundPset) {
                const formattedPset = formatPset({ ...foundPset });
                return formattedPset;
            }
        }

        return sliderValues.find(
            (row) =>
                row.strategy === currentSliders.strategy &&
                row.case === scenario
        );
    }

    const foundSavedPsets = underwritingDataset.filter(
        ({ id }) => fundId === id
    );

    if (
        foundSavedPsets.length === 3 &&
        foundSavedPsets.find(({ scenario }) => scenario !== 'Base')
    ) {
        const foundPset = [...foundSavedPsets].find(
            (row) => row.scenario === scenario
        );

        const formattedPset = formatPset({ ...foundPset });

        return formattedPset;
    }

    if (fundId) {
        const holder = fundSliderValues.find(({ id }) => fundId === id);
        let psetToReturn = {};

        if (holder) {
            switch (scenario) {
                case 'High':
                    psetToReturn = holder.valuesHigh;
                    break;
                case 'Low':
                    psetToReturn = holder.valuesLow;
                    break;
                case 'Base':
                default:
                    psetToReturn = holder.values;
                    break;
            }
        }
        return psetToReturn;
    }

    return false;
};

const formatReturnObject = (graphValues, defaultGraphData) => {
    return {
        ...defaultGraphData,
        base_contrib: defaultGraphData.contrib,
        base_distrib: defaultGraphData.distrib,
        base_nav: defaultGraphData.nav,
        contrib: graphValues.contrib,
        distrib: graphValues.distrib,
        nav: graphValues.nav,
        existing_fund_irr: graphValues.irr,
        existing_tvm: graphValues.tvm,
        existing_break_even: graphValues.breakeven,
        existing_duration: null,
    };
};

const updateGraphHelper = async (
    stateProps,
    tableData,
    reportDate,
    fundSliderValues,
    sliderValues,
    underwritingDataset,
    strategyParameters,
) => {
    const {
        currentSliders,
        commitmentType,
        fundId,
        numYrsFromVinToCurrDate,
    } = stateProps;
    const { scenario, investmentId, commitQtr, closingYear } = currentSliders;

    const tgrid = calculateTgrid(numYrsFromVinToCurrDate, currentSliders);
    const coinvestFlagValue = calculateCoinvestFlagValue(
        currentSliders,
        strategyParameters
    );
    const { existing, future } = getEndpoints(currentSliders);

    let defaultHigh;
    let defaultLow;

    if (!currentSliders.strategy.includes('User Defined')) {
        defaultLow = getSavedPset(
            currentSliders,
            stateProps,
            'Low',
            fundSliderValues,
            sliderValues,
            underwritingDataset
        );
        defaultHigh = getSavedPset(
            currentSliders,
            stateProps,
            'High',
            fundSliderValues,
            sliderValues,
            underwritingDataset
        );
    }
    const defaultBase = getSavedPset(
        currentSliders,
        stateProps,
        'Base',
        fundSliderValues,
        sliderValues,
        underwritingDataset
    );

    const lowPset = scenario === 'Low' ? currentSliders : defaultLow;
    const highPset = scenario === 'High' ? currentSliders : defaultHigh;
    const basePset = scenario === 'Base' ? currentSliders : defaultBase;

    let highParams;
    let lowParams;
    if (highPset) {
        highParams = gatherParameters(highPset, tgrid, coinvestFlagValue);
    }
    if (lowPset) {
        lowParams = gatherParameters(lowPset, tgrid, coinvestFlagValue);
    }
    const baseParams = gatherParameters(basePset, tgrid, coinvestFlagValue);

    try {
        let graphValues;
        let graphValuesHigh;
        let graphValuesLow;
        let graphValuesBase;
        let historical = false;
        if (commitmentType === 'existing' && fundId !== '') {
            // get data for default parameters
            const paramsForDefault = gatherDefaultParameters(
                currentSliders,
                tgrid,
                strategyParameters
            );
            const defaultGraphData = await future(paramsForDefault);

            const tableRow = tableData.find((row) => fundId === row.Id);
            let highFundParams;
            let lowFundParams;
            if (highParams) {
                highFundParams = calcFundParams(
                    currentSliders,
                    tableRow,
                    highParams,
                    reportDate
                );
            }
            if (lowParams) {
                lowFundParams = calcFundParams(
                    currentSliders,
                    tableRow,
                    lowParams,
                    reportDate
                );
            }
            const baseFundParams = calcFundParams(
                currentSliders,
                tableRow,
                baseParams,
                reportDate
            );

            if (highFundParams) {
                const holder = await existing(highFundParams);
                graphValuesHigh = formatReturnObject(holder, defaultGraphData);
            }
            if (lowFundParams) {
                const holder = await existing(lowFundParams);
                graphValuesLow = formatReturnObject(holder, defaultGraphData);
            }
            graphValuesBase = await existing(baseFundParams);
            graphValues = formatReturnObject(graphValuesBase, defaultGraphData);
        } else {
            if (highParams) {
                graphValuesHigh = await future(highParams);
            }
            if (lowParams) {
                graphValuesLow = await future(lowParams);
            }
            graphValues = await future(baseParams);
        }

        if (investmentId && investmentId > 0) {
            const tableRow = tableData.find((row) => fundId === row.Id);
            const portId = tableRow.PortfolioId;

            const historicalParams = {
                investmentId,
                fundVintage: closingYear,
                commitmentQuarter: commitQtr,
                reportDate,
                portfolioId: portId,
                yearly: false,
            };
            historical = await getHistoricalCashFlows(historicalParams);
        }

        return {
            graphValues,
            graphValuesHigh,
            graphValuesLow,
            historical: historical || false,
        };
    } catch (error) {
        return { error };
    }
};

export default updateGraphHelper;
