// @flow
import React, { useContext, useEffect, useState } from 'react';
import { PacingContext, PacingAnalysisContext } from 'context';
import { Grid } from '@mui/material';
import { InputLabel, RadioGroup, InputTextField } from '@ssgglobal/techintnue'

type Props = {
    setNumber: Function,
};

const inputStyle = {
    color: '#268fb3',
    border: '1px solid #BDBFBF',
    borderRadius: '5px',
    padding: '8px 8px 8px 16px',
    width: '100%',
    height: '40px',
}

export default function({ setNumber }: Props) {
    const { pacingClientReadOnlyAndSpiClientId } = useContext(PacingContext);
    const {
        growthType,
        pacingParameters,
        setGrowthType,
    } = useContext(PacingAnalysisContext);

    const { singleGrowthPercent } = pacingParameters;

    const [percent, setPercent] = useState(singleGrowthPercent)

    useEffect(() => {
        setPercent(singleGrowthPercent);
    }, [pacingParameters, singleGrowthPercent])
    
    const growthPaOptions = [
        {
            label: 'Single',
            value: 'single'
        },
        {
            label: 'Multiple',
            value: 'multiple'
        }
    ]

    const updateContext = () => {
        setNumber({
            target: {
                value: percent,
                name: 'singleGrowthPercent',
            }
        })
    }

    const handleChange = (e) => {
        setGrowthType(e);
    }

    return (
        <>
            {pacingClientReadOnlyAndSpiClientId() ? (
                <Grid item>{growthType === 'single' ? 'Single' : 'Multiple'}</Grid>
            ) : (
                <>
                    <Grid container>
                        <Grid item md={4}>
                            <RadioGroup 
                                isThemed
                                label="Growth p.a. (%)"
                                value={growthType}
                                onChange={handleChange}
                                options={growthPaOptions}
                            />
                        </Grid>
                        <Grid item md={8} style={{display: growthType === 'single' ? 'block' : 'none'}}>
                            <InputLabel>Growth p.a. (%)</InputLabel>
                            <InputTextField
                                name='singleGrowthPercent'
                                onChange={(event) => setPercent(event.target.value)}
                                value={percent}
                                onBlur={updateContext}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      updateContext();
                                    }
                                }}
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
