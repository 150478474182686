//@flow
import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap/es';
import swal2 from 'sweetalert2';
import { getOmniData } from 'api';
import { AuthContext, LandingContext, PacingAnalysisContext } from 'context';
import { checkConversusAccess, getNextQuarterStartDate } from 'utils';
import {
    ClientSelector,
    CurrencySelector,
    ForwardNavInputs,
    PortfolioSelector,
    KeepCustomParams,
} from './components';
import moment from 'moment';

type Props = {
    handleModalChange: Function,
    indexVersion: string,
    open: boolean,
    retrieveOmniData: Function,
    toggleModal: Function,
    toggleFwdNav: Function,
    useForwardNav: boolean,
};

const OmniImport = ({
    handleModalChange,
    indexVersion,
    open,
    retrieveOmniData,
    toggleModal,
    toggleFwdNav,
    useForwardNav,
    keepCustomParams,
    setKeepCustomParams,
}: Props) => {
    const [clients, setClients] = useState([]);
    const [portfolios, setPortfolios] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [clientPortfolio, setClientPortfolio] = useState('client');
    const [showAdvancedSection, setShowAdvancedSection] = useState(false);

    const { user, userIsAdmin } = useContext(AuthContext);
    const { setPacingParametersKeyValue } = useContext(PacingAnalysisContext)
    const {
        appendExistingCommitments,
        reportDate,
        setIndexVersion,
        setReportDate,
        tableData,
        setAppendExistingCommitments,
    } = useContext(LandingContext);

    useEffect(() => {
        const queryUrls = ['clients', 'portfolios', 'currencies'];

        queryUrls.forEach(async (type) => {
            let results;
            switch (type) {
                case 'clients':
                    results = await getOmniData('client-organizations/all');
                    break;
                case 'portfolios':
                    results = await getOmniData('portfolios/all');
                    break;
                case 'currencies':
                    results = await getOmniData('currencies/all');
                    break;
                default:
                    break;
            }

            if (!results) {
                swal2.fire({
                    type: 'error',
                    html:
                        'Something went wrong trying to load dropdown options. ' +
                        'Please contact an administrator if the problem persists',
                });

                return;
            }

            if (results.message === 'Invalid email.') {
                return;
            }

            const options = [];
            results.forEach((e) => {
                if (type === 'currencies') {
                    if (e !== 'Fund') {
                        options.push({
                            label: e,
                            value: e,
                        });
                    }
                } else {
                    options.push({
                        label: e.name,
                        value: e.id,
                    });
                }
            });

            if (type === 'clients') {
                setClients(options);
            } else if (type === 'portfolios') {
                setPortfolios(options);
            } else if (type === 'currencies') {
                setCurrencies(options);
            }
        });
    }, []);

    const handlePortfolioChange = async (rows) => {
        const selectedPortfolios = [];
        rows.forEach((rowValue) => {
            selectedPortfolios.push({
                entity_id: rowValue.value,
                entity_type: 'Portfolio',
            });
        });

        handleModalChange(selectedPortfolios);
    };

    const handleDropdownChange = async ({ value, name }) => {
        handleModalChange({
            target: {
                value,
                name,
            },
        });
    };

    const handleAppendChange = async (value) => {
        value === 'append'
            ? setAppendExistingCommitments(true)
            : setAppendExistingCommitments(false);
    };

    const conversusAccess = checkConversusAccess(user);

    const toggleAdvanced = () => {
        const newAdvanced = showAdvancedSection || false;

        setShowAdvancedSection(!newAdvanced);
    };

    // default to current date
    if (!reportDate) {
        setReportDate(moment().format('YYYY-MM-DD'));
    }

    const inputChangeHandler = (e) => {
        const reportDateEdit = e.target.value;
        setReportDate(reportDateEdit);
        // next year if last quarter
        const nextYear = getNextQuarterStartDate(reportDateEdit);
        setPacingParametersKeyValue('startYear', Number(nextYear.substring(0,4)));
    }

    return (
        <Modal isOpen={open} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>SPI Reporting Import</ModalHeader>
            <ModalBody>
                <Row>
                    <Col style={{ marginBottom: '20px' }} md={12}>
                        <FormGroup>
                            <Label>
                                <strong>Report Date</strong>
                            </Label>
                            <Input
                                value={reportDate}
                                onChange={inputChangeHandler}
                                type='date'
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <div>
                            <strong>Select a Client OR Portfolio</strong>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <FormGroup check className='mb-3' inline>
                            <Label check>
                                <Input
                                    id='client'
                                    type='radio'
                                    value='client'
                                    checked={clientPortfolio === 'client'}
                                    onChange={(e) =>
                                        setClientPortfolio(e.target.value)
                                    }
                                    inline='true'
                                />
                                Client
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup check className='mb-3' inline>
                            <Label check>
                                <Input
                                    inline='true'
                                    id='portfolio'
                                    type='radio'
                                    value='portfolio'
                                    checked={clientPortfolio === 'portfolio'}
                                    onChange={(e) =>
                                        setClientPortfolio(e.target.value)
                                    }
                                />
                                Portfolio
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        {clientPortfolio === 'client' ? (
                            <ClientSelector
                                onChange={handleDropdownChange}
                                options={clients}
                            />
                        ) : (
                            <PortfolioSelector
                                onChange={handlePortfolioChange}
                                options={portfolios}
                            />
                        )}
                    </Col>
                    <Col style={{ marginBottom: '20px' }} md={12}>
                        <CurrencySelector
                            onChange={handleDropdownChange}
                            options={currencies}
                        />
                    </Col>
                </Row>

                {tableData.length > 0 && (
                    <div>
                        <Row>
                            <Col
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '5px',
                                }}
                                md={12}
                            >
                                <div>
                                    <strong>Existing Data</strong>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            className='square border rounded w-50'
                            style={{ marginLeft: '5px' }}
                        >
                            <Col md={12}>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            inline='true'
                                            id='override'
                                            type='radio'
                                            value='override'
                                            checked={
                                                appendExistingCommitments ===
                                                false
                                            }
                                            onChange={(e) =>
                                                handleAppendChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        Override (Default)
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col style={{ marginBottom: '20px' }} md={12}>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            inline='true'
                                            id='append'
                                            type='radio'
                                            value='append'
                                            checked={
                                                appendExistingCommitments ===
                                                true
                                            }
                                            onChange={(e) =>
                                                handleAppendChange(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        Append
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                )}

                <Row style={{ marginTop: '20px' }}>
                    <Col sm={12}>
                        {tableData.length > 0 && !appendExistingCommitments && (
                            <KeepCustomParams
                                keepCustomParams={keepCustomParams}
                                setKeepCustomParams={setKeepCustomParams}
                            />
                        )}
                    </Col>
                </Row>
                {!user.spi_client_id && (
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Input
                                    onChange={() => toggleAdvanced()}
                                    checked={showAdvancedSection}
                                    type='checkbox'
                                    style={{
                                        display: 'inline-flex',
                                        top: '-10px',
                                        left: '30px',
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: '20px',
                                        display: 'inline-flex',
                                    }}
                                >
                                    Advanced
                                </Label>
                            </FormGroup>
                            {showAdvancedSection ? (
                                <ForwardNavInputs
                                    setIndexVersion={(e) =>
                                        setIndexVersion(e.target.value)
                                    }
                                    indexVersion={indexVersion}
                                    toggleFwdNav={toggleFwdNav}
                                    useForwardNav={useForwardNav}
                                    conversusAccess={conversusAccess}
                                    userIsAdmin={userIsAdmin}
                                    userSpiClientId={user.spi_client_id}
                                />
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                )}
            </ModalBody>
            <ModalFooter>
                <Button className='primary-button' onClick={retrieveOmniData}>
                    Import
                </Button>
                <Button className='secondary-button' onClick={toggleModal}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default OmniImport;
