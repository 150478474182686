//@flow
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    getPacingUsers,
    getOmniData,
    destroyPacingUser,
    sendActivationEmail,
} from 'api';
import 'ag-grid-enterprise';
import { AuthContext } from 'context';
import Swal from 'sweetalert2';
import { useSnackbar } from 'hooks';
import { MaterialDataGrid } from '@ssgglobal/material-data-grid';
import AdminUserPageContainer from './AdminUserPageContainer';
import UserModal from './UserModal';
import QuickSnackbar from './QuickSnackbar';

const convertUserModelToCamelCase = ({
    user_id: userId,
    last_login: lastLogin,
    email,
    name,
    is_admin: isAdmin,
    spi_client_id: spiClientId,
    spi_client_name: spiClientName,
    parameter_lab_access: parameterLabAccess,
    omni_import_access: omniImportAccess,
    active: isActive,
    trial_user: trialUser,
    access_until: accessUntil,
}) => ({
    userId,
    lastLogin,
    email,
    name,
    isAdmin: isAdmin === 1,
    spiClientId,
    spiClientName,
    parameterLabAccess: parameterLabAccess === 1,
    omniImportAccess: omniImportAccess === 1,
    isActive,
    trialUser,
    accessUntil,
});

const AdminUserPageComponent = ({
    loggedInUserId,
}) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [snackbarMessage, fireSnackbar, clearSnackbar] = useSnackbar();
    const [clients, setClients] = useState([])

    const getAndSetUsers = useCallback(async () => {
        try {
            const pacingUsers = await getPacingUsers();
            setUsers(
                pacingUsers
                    .map((pacingUser) => {
                        let clientName = '';
                        if (pacingUser.spi_client_id) {
                            if (pacingUser.spi_client_id === 999999) {
                                // if trial account
                                clientName = 'Trial Account'
                            } else if (clients[pacingUser.spi_client_id]) {
                                // if client found on client map
                                clientName = clients[pacingUser.spi_client_id]
                            } else if (typeof pacingUser.spi_client_id === 'number') {
                                // if there is client id but not found set id
                                clientName = pacingUser.spi_client_id
                            }
                        }
                        return { ...pacingUser, spi_client_name: clientName }
                    })
                    .map(convertUserModelToCamelCase)
                    .filter(({ userId, email }) => userId !== loggedInUserId && !!email)
            );
            setLoading(false);
        } catch (e) {
            console.log('error', e)
            Swal.fire({
                type: 'error',
                html:
                    'Something went wrong trying to load pacing users. ' +
                    'Please contact an administrator if the problem persists',
            });
        }
    }, [clients]);

    useEffect(() => {
        getAndSetUsers();
    }, [clients]);

    useEffect(() => {
        const fetchClientList = async () => {
            const clientList = await getOmniData('client-organizations/all');
            const clientObject = {}
            clientList.forEach(({ id, name }) => {
                clientObject[id] = name
            })
            setClients(clientObject)
        }
        fetchClientList();
    }, [])

    const closeUserModal = () => setUserModalOpen(false);

    const findUser = useCallback(
        (userId) => {
            const foundUser = users.find(
                ({ userId: thisUserId }) => thisUserId === userId
            );
            return foundUser
                ? {
                      ...foundUser,
                  }
                : null;
        },
        [users]
    );

    const deleteUser = useCallback(
        (userId) => {
            const userIndex =
                userId !== null
                    ? users?.findIndex(
                          ({ userId: thisUserId }) => thisUserId === userId
                      )
                    : null;
            if (userIndex === null || userIndex === -1) {
                return;
            }
            const { name } = users[userIndex];

            Swal.fire({
                type: 'warning',
                text: `Are you sure you would like to delete ${name}? This action cannot be undone.`,
                showCancelButton: true,
                customClass: {
                    confirmButton: 'confirm-button-class',
                    cancelButton: 'cancel-button-class',
                },
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await destroyPacingUser(userId);
                        setUsers(
                            users.filter(
                                ({ userId: thisUserId }) =>
                                    thisUserId !== userId
                            )
                        );
                        fireSnackbar('User Deleted');
                    } catch (error) {
                        Swal.fire(
                            'Error',
                            `Something went wrong while trying to delete ${name}. Please try again or contact an administrator.`,
                            'error'
                        );
                    }
                }
            });
        },
        [findUser]
    );

    const pushUpUser = useCallback(
        (rawNewUser) => {
            const newUser = convertUserModelToCamelCase(rawNewUser);
            const { userId } = newUser;
            const newUsers = [...users];
            const userIndex =
                userId !== null
                    ? users?.findIndex(
                          ({ userId: thisUserId }) => thisUserId === userId
                      )
                    : null;
            const exists = userIndex !== null && userIndex !== -1;
            if (exists) {
                newUsers.splice(userIndex, 1, newUser);
            } else {
                newUsers.push(newUser);
            }
            setUsers(newUsers);
            setUserModalOpen(false);
            fireSnackbar(`User ${exists ? 'Edited' : 'Added'}`);
        },
        [users]
    );

    const editUser = useCallback(
        (userId) => {
            const foundUser = findUser(userId);
            const user = foundUser
                ? {
                      ...foundUser,
                  }
                : true;
            setUserModalOpen(user);
        },
        [findUser]
    );

    const sendEmail = useCallback(
        (userId) => {
            const userIndex =
                userId !== null
                    ? users?.findIndex(
                          ({ userId: thisUserId }) => thisUserId === userId
                      )
                    : null;

            if (userIndex === null || userIndex === -1) {
                return;
            }
            const { email } = users[userIndex];

            Swal.fire({
                type: 'warning',
                text: `Are you sure you would like to send a welcome / activation email to ${email}?`,
                showCancelButton: true,
                customClass: {
                    confirmButton: 'confirm-button-class',
                    cancelButton: 'cancel-button-class',
                },
                confirmButtonText: 'Send',
                cancelButtonText: 'Cancel',
            }).then(async (result) => {
                if (result.value) {
                    try {
                        await sendActivationEmail({ id: userId });
                        fireSnackbar('Email Sent!');
                    } catch (error) {
                        Swal.fire(
                            'Error',
                            `Something went wrong while trying to send the email to ${email}. Please try again or contact an administrator.`,
                            'error'
                        );
                    }
                }
            });
        },
        [findUser]
    );

    return (
        <MaterialDataGrid
            data={users}
            defaultSortBy='name'
            defaultSortOrder='asc'
            defaultPerPage={25}
            paginated
        >
            <AdminUserPageContainer
                setUserModalOpen={setUserModalOpen}
                closeUserModal={closeUserModal}
                deleteUser={deleteUser}
                editUser={editUser}
                loading={loading}
                sendEmail={sendEmail}
            />
            {userModalOpen !== false && (
                <UserModal
                    userModalOpen={userModalOpen}
                    closeUserModal={closeUserModal}
                    pushUpUser={pushUpUser}
                />
            )}
            <QuickSnackbar
                snackbarMessage={snackbarMessage}
                clearSnackbar={clearSnackbar}
            />
        </MaterialDataGrid>
    );
};

export const AdminUserPage = () => {
    const { user } = useContext(AuthContext);
    const {
        user_id: loggedInUserId,
        is_admin: isAdmin,
        spi_client_id: spiClientId,
    } = user || {};

    if (!loggedInUserId || !isAdmin || spiClientId) {
        return <div>You do not have access to this page.</div>;
    }

    return <AdminUserPageComponent loggedInUserId={loggedInUserId} />;
};

export default AdminUserPage;
