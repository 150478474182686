// @flow
import React, { useContext, useEffect, useState, useRef } from 'react';
import {
    Label,
    FormGroup,
} from 'reactstrap';
import {
    Section,
    Button as ButtonT,
    InputTextField,
    InputLabel,
    Grid,
} from '@ssgglobal/techintnue';
import Swal from 'sweetalert2';
import XLSX from '@sheet/edit';
import { Link } from 'react-router-dom';

import {
    AuthContext,
    PacingContext,
    PacingAnalysisContext,
    LandingContext,
    StaticsContext,
    TargetAllocationContext,
    UnderwritingContext,
} from 'context';
import {
    getQuarterFromMonthString,
    PageTitle,
    checkConversusAccess,
} from 'utils';
import {
    postEstimateCommitments,
    postEstimateCommitmentsCash,
    postAnalysis,
    getPortfolioHistoricalCashFlows,
    getOmniData,
} from 'api';

import {
    formatExport,
    formatPortfolioAumAndLiquidAssets,
    downloadExcelFile,
    exportJson,
    checkAddRemoveRows,
    gatherParamsHelper,
    getCommitScheduleStyling,
} from './helpers';

import {
    DateStyle,
    GrowthPercent,
    GrowthOnRadioButtons,
    NewInvestmentStartDate,
    PercentPrivateMarkets,
    PortfolioAsOf,
    TargetYearStyle,
    TotalPortfolio,
    FeesOnNav,
    SelectExportType,
    TotalAssumptionTable,
    CommitmentTable,
    Scenario,
} from './components';
import { Target } from './components/inputs/Target';

type Props = {
    setGrowthOn: Function,
    viewHistorical: boolean,
    numHistoricalYears: number,
    runAnalysis: any,
    setRunAnalysis: any
};

const PacingParameters = ({
    setGrowthOn,
    viewHistorical,
    numHistoricalYears,
    runAnalysis,
    setRunAnalysis
}: Props) => {
    const { user, userIsAdmin } = useContext(AuthContext);
    const pacingAnalysisContext = useContext(PacingAnalysisContext);
    const targetAllocationContext = useContext(TargetAllocationContext);
    const underwritingContext = useContext(UnderwritingContext);
    const { pacingName, pacingClientReadOnlyAndSpiClientId } = useContext(
        PacingContext
    );
    const {
        entityId,
        entityType,
        historicalExportData,
        isOmniImport,
        reportDate,
        tableData,
        tableDataPinnedBottomRowData,
        useForwardNav,
        validateData,
    } = useContext(LandingContext);
    const {
        checkForRenamedStrategy,
        findStrategyByName,
        graphData,
        growthIncrement,
        growthOnLiquidAssets,
        historicalData,
        increment,
        pacingParameters,
        pacingParameters: {
            commitmentSchedule,
            commitmentScheduleOptimizer,
            dateType,
            endYear,
            feesOnNav,
            fiscalMonth,
            fiscalYearPlusOne,
            portfolioAsOf,
            privateMarketExposure,
            quarter,
            singleGrowthPercent,
            scenario = 'base',
            startDate,
            startYear,
            targetYear,
            targetYearType,
            totalAum,
            userEdited,
        },
        portfolioAumAndLiquidAssets,
        setGraphData,
        setHistoricalData,
        setGrowthOnLiquidAssets,
        setPacingParameters,
        setPacingParametersKeyValue,
        setPortfolioAumAndLiquidAssets,
        historicalSetBackValue
    } = useContext(PacingAnalysisContext);
    const {
        exposure,
        strategyAllocation,
        targetAllocation: { currency, targetExposureType },
        setTargetExposureType,
        allocationCommitmentValidation
    } = useContext(TargetAllocationContext);
    const { handleSave, sliderValues } = useContext(UnderwritingContext);
    const {
        assetClassShortNames,
        strategyParameters,
        targetAllocationStrategies,
    } = useContext(StaticsContext);

    const [conversusAccess, setConversusAccess] = useState(false);
    const [savePacingIsOpen, setSavePacingIsOpen] = useState(false);
    const [selectExportTypeIsOpen, setSelectExportTypeIsOpen] = useState(false);
    const [smoothing] = useState(false);
    const [pageLoad, setPageLoad] = useState(true);

    // handles the change of target exposure
    const handleTargetExposureChange = (newTargetExposureType) => {
        if (newTargetExposureType == 'percent') {
            // re calculate to percentage
            const total = commitmentSchedule.map(({ alloc }) => alloc).reduce((a, b) => a + b);
            const newCommitment = commitmentSchedule.map((commitment) => ({
                ...commitment,
                alloc: parseInt((parseInt(commitment.alloc) / parseInt(total)) * 100),
            }));
            setPacingParametersKeyValue('commitmentSchedule', newCommitment);
        }
        setTargetExposureType(newTargetExposureType)
    }

    /** Checks conversusAccess based on !spi_client_id and presence in authorizedUsers array */
    const confirmConversusAccess = () => {
        const newConversusAccess = checkConversusAccess(user);
        setConversusAccess(newConversusAccess);
    };
    /**
     * Figure out if start year is +1 due to fiscal year
     * ending earlier in the year and start date
     */
    const calculateFiscalStart = () => {
        if (dateType === 'fiscal') {
            const month = startDate.substring(5, 7);

            const parsedFiscalMonth =
                fiscalMonth === '' ? 'September' : fiscalMonth;
            setPacingParametersKeyValue('fiscalMonth', parsedFiscalMonth);

            const startQuarter = getQuarterFromMonthString(month);
            const fiscalEndQuarter = getQuarterFromMonthString(
                parsedFiscalMonth
            );

            if (startQuarter > fiscalEndQuarter) {
                setPacingParametersKeyValue('fiscalYearPlusOne', true);
            } else {
                setPacingParametersKeyValue('fiscalYearPlusOne', false);
            }
        } else {
            setPacingParametersKeyValue('fiscalYearPlusOne', false);
        }
    };

    /**
     * Validate and format number after editing
     *
     * @param event
     */
    const setNumber = (event) => {
        let { value } = event.target;
        const { name } = event.target;

        if (value === '') {
            value = 0;
        }

        if (name === 'singleGrowthPercent') {
            if (value < -98) {
                value = -98;
            }
            if (value > 100) {
                value = 100;
            }
        } else {
            if (value < 0) {
                value = 0;
            }
            if (name === 'totalAum' && value > 1000000) {
                value = 1000000;
            } else if (name === 'feesOnNav' && value > 100) {
                value = 100;
            } else if (name === 'smoothing' && value > 5000) {
                value = 5000;
            }
        }

        value = Math.round(parseFloat(value) * 1000) / 1000;

        setPacingParametersKeyValue(name, value);
    };

    /**
     * Check and set field as userEdited
     *
     * @param event
     */
    const setUserEdited = (event) => {
        const editedName = event.target.name;
        const paramsCopy = { ...pacingParameters };
        if (!paramsCopy.userEdited?.includes(editedName)) {
            paramsCopy.userEdited.push(editedName);
        }
        setPacingParameters(paramsCopy);
    };

    /**
     * Validate and update endYear
     *
     * @param event
     */
    const handleYearChange = async (event) => {
        let { value } = event.target;
        const { name } = event.target;

        if (value.length > 4) {
            value = value.substring(0, 4);
        } else if (value === '' || Number.isNaN(value)) {
            value = 0;
        }
        value = parseInt(value, 10);
        if (value > startYear + 20) {
            value = startYear + 20;
        }

        if (value.toString().length === 4) {
            await setPacingParametersKeyValue(name, value);
        }
    };

    /**
     * Validate and update targetYear
     *
     * @param event
     */
    const handleTargetYearBlur = (event) => {
        let { value } = event.target;
        const { name } = event.target;

        if (value <= startYear) {
            value = Number(startYear) + 1;
        }

        if (value > endYear) {
            value = Number(endYear);
        }

        setPacingParametersKeyValue(name, value);
    };

    /**
     * Handle straighforward var updates to pacing parameters
     */
    const handleBasicChange = async ({ target: { name, value } }) => {
        setPacingParametersKeyValue(name, value);
    };

    /**
     * Update startDate
     *
     * @param {string} name
     * @param {string} value
     */
    const setStartDate = async (name, value) => {
        setPacingParametersKeyValue(name, value);
    };

    /**
     * Update scenario
     *
     * @param {string} name
     * @param {string} value
     */
    const setScenario = (value) => {
        setPacingParametersKeyValue('scenario', value);
    };

    /**
     * Update Portfolio AuM and Liquid Assets
     */
    const handlePortfolioAumAndLiquidAssets = () => {
        const { port_conversus: portConversus } = graphData;
        if (portConversus) {
            const {
                port_aum_yr: portfolioAumData,
                liquid_yr: liquidAssetData,
            } = portConversus;
            if (portfolioAumData !== null) {
                const formattedPortfolioAumAndLiquidAssets = formatPortfolioAumAndLiquidAssets(
                    portfolioAumData,
                    growthOnLiquidAssets ? liquidAssetData : null,
                    startYear,
                    fiscalYearPlusOne
                );

                setPortfolioAumAndLiquidAssets(
                    formattedPortfolioAumAndLiquidAssets
                );
            }
        }
    };

    /**
     * Validate liquid assets dates
     */
    const checkLiquidAssetInputs = () => {
        let message = '';

        if (portfolioAsOf > startDate) {
            message =
                'Please input Porfolio as of date before Start Date for New Investments.';
        }

        if (portfolioAsOf < reportDate) {
            message = 'Please input Porfolio as of date after Report Date.';
        }

        if (message !== '') {
            Swal.fire({
                type: 'error',
                html: message,
            });
        }
        return message;
    };

    /**
     * Confirm all required paramters for estimate commitments and run analysis
     * are filled in
     */
    const validatePacingParameters = () => {
        let message = '';

        if (!totalAum) {
            message = '"Total Portfolio AuM (M)" is required';
        }
        if (
            !privateMarketExposure &&
            targetExposureType !== 'noNewCommitments' &&
            targetExposureType !== 'currency'
        ) {
            message = '"Private Markets as % of total AuM" is required';
        }
        if (singleGrowthPercent === '') {
            message = '"Growth p.a. (%)" is required';
        }
        if (portfolioAsOf === '') {
            message = '"Portfolio as of" is required';
        }
        if (!targetYear) {
            message = '"Target Year" is required';
        }
        if (!endYear) {
            message = '"Final Year" is required';
        }
        if (growthOnLiquidAssets) {
            const error = checkLiquidAssetInputs();
            if (error !== '') {
                message = error;
            }
        }

        if (message !== '') {
            Swal.fire({
                type: 'error',
                html: message,
            });
            return false;
        }
        return true;
    };

    /**
     * Create a neat object with formatted paramters for
     * estimate commitments and run analysis
     */
    const gatherParameters = () => {
        return gatherParamsHelper(
            pacingAnalysisContext,
            targetAllocationContext,
            targetAllocationStrategies,
            underwritingContext,
            tableData,
            smoothing,
            assetClassShortNames,
            strategyParameters,
            reportDate,
            useForwardNav
        );
    };

    const gatherHistoricalParams = async () => {
        const data = await getOmniData('currencies/codesAndSymbols');
        const { code = '' } = data.find(({ symbol }) => {
            return symbol === currency;
        });

        const ids = [];
        tableData.forEach((row) => {
            if (row.investmentId) {
                ids.push(row.investmentId);
            }
        });

        return {
            investmentId: ids,
            portfolioId: entityId === 0 ? [0] : entityId,
            reportDate: reportDate !== '' ? reportDate : '2019-03-31',
            currency: code || '$',
            useFiscal: dateType,
            fiscalEndMonth: fiscalMonth,
            numberofyears: numHistoricalYears,
            entityType: entityType,
        };
    };

    /**
     * Estimate commitments and set the commitment schedule
     * to the new values.
     */
    const handleEstimate = async () => {
        const dataValidated = await validateData('pacing');
        const pacingParametersValidated = validatePacingParameters();

        if (dataValidated && pacingParametersValidated) {
            Swal.showLoading();
            setPortfolioAumAndLiquidAssets([]);

            const params = gatherParameters();

            try {
                let results = null;
                if (growthOnLiquidAssets) {
                    results = await postEstimateCommitmentsCash(params);
                } else {
                    results = await postEstimateCommitments(params);
                }

                const newCommitmentSchedule = [];
                results.Strategy.forEach(() => {
                    newCommitmentSchedule.push({});
                });

                Object.keys(results).forEach((key) => {
                    results[key].forEach((value, index) => {
                        if (key === 'Strategy') {
                            newCommitmentSchedule[index].strategy = value;
                        // } else if (key === 'userEdited') {
                        //     newCommitmentSchedule[index][key] = value;
                        } else {
                            newCommitmentSchedule[index][key] = value;
                        }
                    });
                });

                newCommitmentSchedule.map((row, index) => {
                    const formattedRow = row;

                    if (row.strategy.includes('User Defined')) {
                        const displayName = checkForRenamedStrategy(
                            row.strategy
                        );

                        formattedRow.strategy = displayName;
                    }
                    let uEdited = [];

                    if (row.userEdited?.length) {
                        row.userEdited.map((rowEdited) => {
                            if( commitmentScheduleOptimizer[index][rowEdited] === 1 ) {
                                uEdited.push(rowEdited)
                            }
                        })
                    }
                    formattedRow.userEdited = uEdited;
                    formattedRow.min = commitmentSchedule[index].min;
                    formattedRow.max = commitmentSchedule[index].max;
                    formattedRow.increment = commitmentSchedule[index].increment;
                    formattedRow.alloc = commitmentSchedule[index].alloc;
                    formattedRow.commitQtr = commitmentSchedule[index].commitQtr;
                    formattedRow.serverNames = commitmentSchedule[index].serverNames;
                        
                    return formattedRow;

                });

                setPacingParametersKeyValue(
                    'commitmentSchedule',
                    newCommitmentSchedule
                );
                Swal.close();
            } catch (error) {
                Swal.close();

                Swal.fire({
                    type: 'error',
                    html:
                        'Something went wrong trying to retrieve commitment estimates. ' +
                        'Please contact an administrator if the problem persists.',
                    showCancelButton: true,
                    cancelButtonText: 'Download error files',
                }).then((result) => {
                    if (result.dismiss === 'cancel') {
                        const mergedState = {
                            ...targetAllocationContext,
                            ...underwritingContext,
                            ...pacingAnalysisContext,
                        };

                        exportJson(params, 'estimateErrorParams.json');
                        exportJson(mergedState, 'estimateErrorState.json');
                    }
                });
            }
        }
    };

    /**
     * Run the analysis and set the graph data to load the graphs
     */
    const runPacing = async () => {
        const dataValidated = await validateData('pacing');
        const pacingParametersValidated = validatePacingParameters();

        if (dataValidated && pacingParametersValidated) {
            Swal.showLoading();
            let historicalParams = null;

            const params = await gatherParameters();
            if (isOmniImport && user.omni_import_access) {
                historicalParams = await gatherHistoricalParams();
            }

            try {
                const results = await postAnalysis(params);
                if (historicalParams?.investmentId.length > 0) {
                    const historical = await getPortfolioHistoricalCashFlows(
                        historicalParams
                    );
                    await setHistoricalData(historical);
                }
                await setGraphData(results);
                Swal.close();
            } catch (error) {
                Swal.close();

                Swal.fire({
                    type: 'error',
                    html:
                        'Something went wrong trying to run the analysis. ' +
                        'Please contact an administrator of the problem persists.',
                    showCancelButton: true,
                    cancelButtonText: 'Download error files',
                }).then((result) => {
                    if (result.dismiss === 'cancel') {
                        const mergedState = {
                            ...targetAllocationContext,
                            ...underwritingContext,
                            ...pacingAnalysisContext,
                        };

                        exportJson(params, 'analysisErrorParams.json');
                        exportJson(mergedState, 'analysisErrorState.json');
                    }
                });
            } finally {
                setRunAnalysis(false);
            }
        }
        
    };

    /**
     * Toggle modals
     *
     * @param {string} type
     */
    const toggleModal = (type) => {
        if (type === 'savePacingIsOpen') {
            setSavePacingIsOpen(!savePacingIsOpen);
        } else if (type === 'selectExportTypeIsOpen') {
            setSelectExportTypeIsOpen(!selectExportTypeIsOpen);
        }
    };

    /**
     * Export the pacing.
     * Can export data grouped by quarter or by year
     */
    const exportPacing = (type) => {
        toggleModal('selectExportTypeIsOpen');
        if (Object.keys(graphData).length !== 0) {
            const {
                formattedData,
                formattedMiscInputs,
                formattedRawData,
            } = formatExport(
                type,
                tableData,
                pacingAnalysisContext,
                targetAllocationContext,
                { ...underwritingContext },
                smoothing,
                assetClassShortNames,
                strategyParameters,
                targetAllocationStrategies,
                reportDate,
                useForwardNav,
                { ...graphData },
                { ...historicalData },
                viewHistorical,
                historicalExportData,
                historicalSetBackValue.value
            );

            // destructuring after formatInputs in case it removes var
            try {
                // Retrieves te template, inject with data, download
                downloadExcelFile(
                    formattedData,
                    formattedMiscInputs,
                    formattedRawData,
                    privateMarketExposure,
                    pacingName
                );
            } catch (e) {
                Swal.fire({
                    type: 'error',
                    text:
                        'Unable to export the pacing. Please save your pacing and contact an administrator at pacing@stepstonegroup.com.',
                });
            }
        } else {
            Swal.fire({
                type: 'error',
                text: 'No analysis to export!',
            });
        }
    };

    /**
     * Update private market expsure value
     *
     * @param value
     */
    const handlePrivMrkExpOnBlur = async ({ target: { value } }) => {
        const newValue = value < 0 || value === '' ? 0 : parseFloat(value);

        setPacingParametersKeyValue('privateMarketExposure', newValue);
    };

    /**
     * Update portfolio as of date
     *
     * @param value
     */
    const updatePortfolioAsOf = ({ target: { value } }) => {
        if (growthOnLiquidAssets) {
            checkLiquidAssetInputs();
        }

        setPacingParametersKeyValue('portfolioAsOf', value);
    };

    /**
     * Export raw output data
     */
    const exportRawPacing = () => {
        const workbook = XLSX.utils.book_new();
        const miscInformation = [];
        let count = 0;
        Object.keys(graphData).forEach((key) => {
            const modified = Object.keys(graphData[key]).map((innerKey) => {
                return graphData[key][innerKey];
            });
            if (typeof modified[0] === 'object') {
                const worksheet = XLSX.utils.aoa_to_sheet(modified);
                XLSX.utils.book_append_sheet(workbook, worksheet, key);
            } else {
                modified.unshift(key);
                miscInformation[count] = modified;
                count++;
            }
        });
        const worksheet = XLSX.utils.aoa_to_sheet(miscInformation);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'miscInformation');

        XLSX.writeFile(workbook, 'testing.xlsx');
    };

    /**
     * update liquid assets related fields
     *
     * @param target
     */
    const handleGrowthOn = ({ target }) => {
        let isLiquidAssets = false;

        if (target.value === 'liquid-assets') {
            isLiquidAssets = true;
            checkLiquidAssetInputs();
        }

        setGrowthOnLiquidAssets(isLiquidAssets);
        setPortfolioAumAndLiquidAssets([]);

        setGrowthOn(isLiquidAssets);
    };

    /**
     * Update commitment schedule & optimizer
     */
    const updateCommitmentSchedule = async () => {
        const values = await checkAddRemoveRows(
            targetAllocationStrategies,
            commitmentSchedule,
            commitmentScheduleOptimizer,
            assetClassShortNames,
            exposure,
            strategyAllocation,
            checkForRenamedStrategy,
            findStrategyByName,
            startYear, 
            endYear,
            fiscalYearPlusOne,
            sliderValues,
            scenario,
        );
        const { newSchedule, newOptimizer } = values;
        setPacingParametersKeyValue('commitmentSchedule', newSchedule);
        setPacingParametersKeyValue(
            'commitmentScheduleOptimizer',
            newOptimizer
        );
    };

    const updateHistoricalCashFlows = async () => {
        Swal.showLoading();
        const historicalParams = await gatherHistoricalParams();
        if (historicalParams.investmentId.length) {
            const historical = await getPortfolioHistoricalCashFlows(
                historicalParams
            );

            setHistoricalData(historical);
        }

        Swal.close();
    };

    /**
     * Initialize portfolioAsOf to reportDate
     */
    const setDefaultPortfolioAsOf = () => {
        if (portfolioAsOf === '' && reportDate !== '') {
            setPacingParametersKeyValue('portfolioAsOf', reportDate);
        }
    };

    useEffect(() => {
        if (viewHistorical && tableData.length > 0) {
            updateHistoricalCashFlows();
        }
    }, [numHistoricalYears, historicalSetBackValue.value]);

    useEffect(() => {
        calculateFiscalStart();
    }, [dateType, fiscalMonth, startDate]);

    useEffect(() => {
        updateCommitmentSchedule();
    }, [scenario, targetExposureType, fiscalYearPlusOne, startYear, endYear]);

    useEffect(() => {
        if (Object.keys(graphData).length !== 0 && !pageLoad) {
            runPacing();
        }
    }, [increment, growthIncrement]);

    useEffect(() => {
        if (!pageLoad && runAnalysis) {
            runPacing();
        }
    }, [runAnalysis]);

    useEffect(() => {
        handlePortfolioAumAndLiquidAssets();
    }, [graphData]);

    useEffect(() => {
        setPageLoad(false);
        confirmConversusAccess();
        updateCommitmentSchedule();
        setDefaultPortfolioAsOf();

        if (graphData.portConversus) {
            handlePortfolioAumAndLiquidAssets();
        }
    }, []);

    const formattedCommitmentSchedule = Array.isArray(commitmentSchedule)
        ? commitmentSchedule
        : [];
    const disableCheck = targetExposureType === 'noNewCommitments';
    const percentDisableCheck =
        targetExposureType === 'noNewCommitments' ||
        targetExposureType === 'currency';

    const inputStyle = {
        display: 'flex',
        width: '80px',
        height: '40px',
        padding: '8px 16px',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '5px',
        border: '1px solid #BDBFBF',
        background: '#FFF',
    }

    const PageTitlePacing = () => {
        const renderPage = () => {
            return (
                <Grid container item direction="row" sx={{ gap: '16px' }}>
                    <Grid
                        container
                        item
                        direction="row"
                        alignItems="center"
                        sx={{ width:'140px', gap: '4px' }}
                    >
                        <Grid item sx={{ '& > label': { margin: 0 } }}>
                            <InputLabel>Final Year</InputLabel>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                width:'80px',
                                '.MuiInputBase-root': {
                                    minHeight: 'unset !important',
                                    height: '38px',
                                }
                            }}
                            className='end-year-input'
                        >
                            {pacingClientReadOnlyAndSpiClientId() ? (
                                endYear
                            ) : (
                                <InputTextField
                                    defaultValue={endYear}
                                    name='endYear'
                                    onBlur={handleYearChange}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item >
                        <ButtonT
                            theme="secondary"
                            onClick={() =>
                                toggleModal('selectExportTypeIsOpen')
                            }
                        >
                            Export Analysis
                        </ButtonT>
                    </Grid>
                </Grid>
            )
        }
        
        return (
            <PageTitle title='Pacing Analysis' handleSave={handleSave} render={renderPage} isPacing/>
        )
    }

    const isAdminUser = conversusAccess || (userIsAdmin ? true : false);

    const colSize = isAdminUser ? 2 : 3;

    return (
        <>
            <PageTitlePacing />
            <Section title="Total Plan Assumptions">
                <Grid container spacing={2} style={{marginBottom: '14px'}}>
                    {isAdminUser && (
                        <Grid item md={1}>
                            <GrowthOnRadioButtons
                                growthOnLiquidAssets={growthOnLiquidAssets}
                                setGrowthOn={handleGrowthOn}
                            />
                        </Grid>
                    )}
                    <Grid item md={colSize}>
                        <TotalPortfolio
                            totalAum={totalAum}
                            setNumber={setNumber}
                        />
                    </Grid>
                    <Grid item md={colSize}>
                        <PortfolioAsOf
                            portfolioAsOf={portfolioAsOf}
                            updatePortfolioAsOf={updatePortfolioAsOf}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <GrowthPercent setNumber={setNumber} />
                    </Grid>
                    {isAdminUser && (
                        <Grid item md={3}>
                            <FeesOnNav
                                feesOnNav={feesOnNav || 0}
                                setNumber={setNumber}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container>
                    <Grid item md={12}>
                        <TotalAssumptionTable
                            commitmentSchedule={formattedCommitmentSchedule}
                            endYear={endYear}
                            startYear={startYear}
                            fiscalYearPlusOne={fiscalYearPlusOne}
                            getCommitScheduleStyling={getCommitScheduleStyling}
                        />
                    </Grid>
                </Grid>
            </Section>
            <br />
            <Section title="Commitment Schedule">
            {allocationCommitmentValidation() === true ? (
                    <Grid container>
                        <Grid item>
                            <p style={{display:'inline-block'}}>No target strategies were selected. You can add target strategies by clicking + Add Investment Strategies on the </p><Link to='/parameter-lab'> Underwriting Page</Link>
                        </Grid>
                    </Grid>
                ) : (
                <Grid container spacing={2}>
                <Grid container item spacing={2} columns={{ md: 15 }}>
                    <Grid container item md={3} direction="column">
                        <Scenario
                            value={scenario}
                            onChange={setScenario}
                        />
                    </Grid>
                    <Grid container item md={3}>
                        <NewInvestmentStartDate
                            disableCheck={disableCheck}
                            quarter={quarter}
                            setStartDate={setStartDate}
                            startDate={startDate}
                            useForwardNav={useForwardNav}
                            year={startYear}
                        />
                    </Grid>
                    <Grid container item md={3}>
                        <DateStyle
                            dateType={dateType}
                            handleDateTypeChange={handleBasicChange}
                            disableCheck={disableCheck}
                            fiscalMonth={fiscalMonth}
                            handleMonthChange={handleBasicChange}
                        />
                    </Grid>
                    <Grid container item md={3}>
                        <TargetYearStyle
                            disableCheck={disableCheck}
                            handleTargetTypeChange={handleBasicChange}
                            handleTargetYearBlur={handleTargetYearBlur}
                            targetType={targetYearType}
                            targetYear={targetYear}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={2} columns={{ md: 15 }}>
                    <Grid container item md={3}>
                        <Target
                            value={targetExposureType}
                            onChange={handleTargetExposureChange}
                        />
                    </Grid>
                    <Grid container item md={3}>
                        {!percentDisableCheck ? (
                            <PercentPrivateMarkets
                                handlePrivMrkExpOnBlur={handlePrivMrkExpOnBlur}
                                percentDisableCheck={percentDisableCheck}
                                privateMarketExposure={privateMarketExposure}
                            />
                        ) : null }
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <CommitmentTable
                        commitmentSchedule={formattedCommitmentSchedule}
                        conversusAccess={conversusAccess}
                        disableCheck={disableCheck}
                        endYear={endYear}
                        startYear={startYear}
                        quarter={quarter}
                        fiscalYearPlusOne={fiscalYearPlusOne}
                        getCommitScheduleStyling={getCommitScheduleStyling}
                        portfolioAumAndLiquidAssets={portfolioAumAndLiquidAssets}
                        handleEstimate={handleEstimate}
                    />
                </Grid>
                </Grid>
                )}
                <SelectExportType
                    open={selectExportTypeIsOpen}
                    toggleModal={() =>
                        toggleModal('selectExportTypeIsOpen')
                    }
                    exportAnalysis={exportPacing}
                />
            </Section>
        </>
    );
};

export default PacingParameters;
