// @flow
import React, { useEffect, useState, useContext } from 'react';
import { DropdownTextField } from '@ssgglobal/techintnue'
import Swal from 'sweetalert2';
import { getNextQuarterStartDate, getQuarterFromDate, getQuarterAndYearFromDate, setQuartersDateOptions } from 'utils';
import { PacingAnalysisContext } from 'context'

type Props = {
    quarter: string,
    year: string,
    reportDate: string,
    updateDate: Function,
    disableCheck: boolean,
};

const InputDateNewInvestments = ({
    reportDate,
    quarter,
    year,
    updateDate,
    disableCheck,
}: Props) => {
    const [splitReportDate] = useState(reportDate.split('-'));
    const offsetStartDate = getNextQuarterStartDate(reportDate);
    const [startDateValue, setStartDateValue] = useState(getQuarterAndYearFromDate(offsetStartDate))
    const quartersPeriod = setQuartersDateOptions(false, offsetStartDate, 5)
    const {setPacingParametersKeyValue, pacingParameters} = useContext(PacingAnalysisContext)

    /**
     * Check if the start date is after the report date
     *
     * @param selectedQuarter
     * @param selectedYear
     * @returns {object}
     */
    const validateStartDate = (selectedQuarter, selectedYear) => {
        const reportQuarter = Math.ceil(
            (parseInt(splitReportDate[1], 10) + 1) / 4
        );
        let quarterValue = selectedQuarter.substring(1);
        let yearValue = selectedYear;

        if (
            Number(selectedYear) <= Number(splitReportDate[0]) &&
            Number(quarterValue) <= Number(reportQuarter)
        ) {
            Swal.fire({
                type: 'error',
                html:
                    'Please select a date later than the report date. ' +
                    'The start date has been defaulted to the earliest ' +
                    'available date based on the report date.',
            });
            if (reportQuarter < 4) {
                quarterValue = Number(reportQuarter) + 1;
            } else if (reportQuarter === 4) {
                quarterValue = 1;
                yearValue = Number(splitReportDate[0]) + 1;
                yearValue = yearValue.toString();
            }
        }

        return {
            quarter: `Q${quarterValue}`,
            startYear: Number(yearValue),
        };
    };

    /**
     * Create a date string based on quarter and year selection.
     * Will update with either a quarter change or a year change
     *
     * @param target
     */
    const handleUpdateInput = ({ target: { label, value } }) => {
        const qrtYr = value.split(" ");
        setStartDateValue(value);
        setPacingParametersKeyValue('startDateNewInvestments', value)

        const validatedDate = validateStartDate(
            qrtYr[0],
            qrtYr[1]
        );

        updateDate('quarter', validatedDate['quarter']);
        updateDate('startYear', validatedDate['startYear']);
    };

    /**
     * Validate date on load.
     * It could be an old pacing with update dates
     */
    useEffect(() => {
        const dateToParse = reportDate ? new Date(reportDate) : null;
        const initialStartDate = getNextQuarterStartDate(dateToParse);
        const initialQuarter = getQuarterFromDate(initialStartDate);
        const initialStartYear = initialStartDate.substring(0, 4);
        if (year === '') {
            updateDate('startYear', Number(initialStartYear));
        }
        if (quarter === '') {
            updateDate('quarter', initialQuarter);
        }
    }, []);

    useEffect(() => {
        const found = quartersPeriod.find(({ value }) => value === pacingParameters.startDateNewInvestments);
        if (!found) {
            // if selection not in options get the current display value
            setPacingParametersKeyValue('startDateNewInvestments', startDateValue)
        }
    }, [quartersPeriod])

    return (
        <DropdownTextField 
            options={quartersPeriod}
            value={pacingParameters.startDateNewInvestments || startDateValue}
            onChange={(e) => handleUpdateInput(e)}
        />
    );
};

export default InputDateNewInvestments;
