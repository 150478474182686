// @flow
import React, { useContext } from 'react';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import swal2 from 'sweetalert2';
import { LandingContext, PacingAnalysisContext } from 'context';
import { getNextQuarterStartDate } from 'utils';

type Props = {
    isOpen: boolean,
    toggleModal: Function,
    loadedSavedPacing: boolean
};

export default function ReportDateModal({ isOpen, toggleModal, loadedSavedPacing }: Props) {
    const { reportDate, setReportDate } = useContext(LandingContext);
    const { setPacingParametersKeyValue } = useContext(PacingAnalysisContext)

    const verifyDateSet = async () => {
        toggleModal();
        if (reportDate === '' || reportDate.length < 10) {
            setReportDate('----/--/--');
            return swal2.fire({
                type: 'warning',
                html: 'You must set a report date',
            });
        }
        return undefined;
    };

    const inputChangeHandler = (e) => {
        const reportDateEdit = e.target.value;
        setReportDate(reportDateEdit);
        // next year if last quarter
        const nextYear = getNextQuarterStartDate(reportDateEdit);
        setPacingParametersKeyValue('startYear', Number(nextYear.substring(0,4)));
    }

    return (
        <Modal isOpen={isOpen} toggle={verifyDateSet}>
            <ModalHeader toggle={verifyDateSet}>Set Report Date</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Label>
                                <strong>Report Date:</strong>
                            </Label>
                            <Input
                                value={reportDate}
                                onChange={inputChangeHandler}
                                type='date'
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
            {loadedSavedPacing ? "Changing the report date will not update the data in the existing commitments table. If you want to roll forward your data to a new report date you may either re-upload data from Excel or re-import the data from Omni." : ''}
            
                <Button color='success' onClick={verifyDateSet}>
                    Save & Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}
