import React from 'react';
import {
    FormatNumberWithLock,
    FormatNumberWithoutLock,
    FormatNumber,
    NumericEditor,
    numericValidator,
    FormatPercentage,
    dropdownValidator,
} from 'utils';
import { getCommitScheduleStyling, getCommitScheduleStylingForPercentage } from 'PacingAnalysis/components/PacingParameters/helpers';
import { TotalsRowRenderer } from 'Landing/components';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@ssgglobal/techintnue'

/**
 * Column defintions for Commitment Schedule
 * For both the commitment schedule and
 * total portfolio net contributions
 *
 * @param {*} updateValue
 * @param {*} startYear
 * @param {*} endYear
 * @param {*} fiscalYearPlusOne
 */
export function CommitmentScheduleColumnDefinitions(
    updateValue = null,
    quarter,
    startYear,
    endYear,
    fiscalYearPlusOne,
    toggleLock,
    optimizer = [],
    handleValueClick,
    targetExposureType,
    startDateNewInvestments
) {
    const tableDefinitions = [];
    const TargetHeader = () => {
        const header = 'Target';
        switch(targetExposureType) {
            case 'percent': return `${header} (%)`
            case 'currency': return `${header} NAV`
            default: return header
        }
    }

    const withoutLockRenderer = {
        component: 'formatNumberWithoutLock',
        params: {
            toggleLock,
            optimizer,
            handleValueClick
        }
    }

    const totalRenderer = {
        component: 'totalsRowRenderer',
    }

    const totalPercentageRenderer = {
        component: 'formatPercentage',
        params: {
            isPercentage: targetExposureType === 'percent',
            decimal: 2,
            decimalWhenNeeded: true,
        }
    }

    const formatPercentageRenderer = {
        component: 'formatPercentage',
        params: {
            toggleLock,
            optimizer,
            handleValueClick,
            isPercentage: targetExposureType === 'percent',
            decimal: 2,
            decimalWhenNeeded: true,
        }
    }

    tableDefinitions.push({
        headerName: 'Pacing Strategy',
        field: 'strategy',
        pinned: 'left',
        editable: false,
        width: 200,
        resizable: false,
    });

    tableDefinitions.push({
        headerComponent: TargetHeader,
        field: 'alloc',
        cellStyle: targetExposureType == 'percent' ? getCommitScheduleStylingForPercentage : getCommitScheduleStyling,
        pinned: 'left',
        type: 'numericColumn',
        editable: true,
        width: 130,
        cellEditor: 'numericEditor',
        cellEditorParams: ({ api }) => {
            return {
                field: 'alloc',
                updateValue: (value, field, data) => {
                    updateValue(value, field, data);
                    if (targetExposureType == 'percent') {
                        const targets = []
                        api.forEachNode(node => targets.push(node.data.alloc));
                        const total = targets.reduce((a, b) => a + b);
                        if (total >= 100) {
                            updateValue(100 - total + value, field, data);
                        }
                    }
                },
            };
        },
        onCellValueChanged: numericValidator,
        cellRendererSelector: (params) => {
            if(params.data.strategy === 'Total'){
                return totalPercentageRenderer;
            } else {
                return formatPercentageRenderer;
            }
        },
    });

    tableDefinitions.push({
        headerComponent: () => {
            return (
                <>
                    Min{' '}
                    <Tooltip
                        isThemed
                        placement="top"
                        title='Sets the minimum annual commitment amount to the corresponding pacing strategy'
                    >
                        <InfoIcon style={{ fontSize: 15 }} />
                    </Tooltip>
                </>
            );
        },
        field: 'min',
        cellStyle: getCommitScheduleStyling,
        pinned: 'left',
        type: 'numericColumn',
        editable: true,
        width: 80,
        cellEditor: 'numericEditor',
        cellEditorParams: () => {
            return {
                field: 'min',
                updateValue,
            };
        },
        onCellValueChanged: numericValidator,
        cellRendererSelector: (params) => {
            if(params.data.strategy === 'Total'){
                return totalRenderer
            } else {
                return withoutLockRenderer
            }
        },
    });

    tableDefinitions.push({
        headerComponent: () => {
            return (
                <>
                    Max{' '}
                    <Tooltip
                        isThemed
                        placement="top"
                        title='Sets the maximum annual commitment amount to the corresponding pacing strategy'
                    >
                        <InfoIcon style={{ fontSize: 15 }} />
                    </Tooltip>
                </>
            );
        },
        field: 'max',
        cellStyle: getCommitScheduleStyling,
        pinned: 'left',
        type: 'numericColumn',
        editable: true,
        width: 80,
        cellEditor: 'numericEditor',
        cellEditorParams: () => {
            return {
                field: 'max',
                updateValue,
            };
        },
        onCellValueChanged: numericValidator,
        cellRendererSelector: (params) => {
            if(params.data.strategy === 'Total'){
                return totalRenderer
            } else {
                return withoutLockRenderer
            }
        },
    });

    tableDefinitions.push({
        headerComponent: () => {
            return (
                <>
                    Increment{' '}
                    <Tooltip
                        isThemed
                        placement="top"
                        title="Sets the commitment schedule to round to the increment set. e.g. an increment of ‘5’ will result in commitments of ‘5, 10, 15. etc.’ an increment of ‘10’ will result in commitments of '10, 20, 30. etc.'"
                    >
                        <InfoIcon style={{ fontSize: 15 }} />
                    </Tooltip>
                </>
            );
        },
        field: 'increment',
        cellStyle: getCommitScheduleStyling,
        pinned: 'left',
        type: 'numericColumn',
        editable: true,
        width: 100,
        cellEditor: 'numericEditor',
        cellEditorParams: () => {
            return {
                field: 'increment',
                updateValue,
            };
        },
        onCellValueChanged: numericValidator,
        cellRendererSelector: (params) => {
            if(params.data.strategy === 'Total'){
                return totalRenderer
            } else {
                return withoutLockRenderer
            }
        },
    });

    tableDefinitions.push({
        headerName: 'Qtr Commit',
        field: 'commitQtr',
        pinned: 'left',
        headerTooltip:
            'For existing commitments, Qtr Committed sets the quarter the commitment was made. For future commitments, the selection will determine what quarter commitments will be made in each year. Selecting "All" will split the commitments across all four quarters in each year. "All" is selected by default.',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: () => {
            return {
                values: ['All', 'Q1', 'Q2', 'Q3', 'Q4'],
                updateValue,
                handleValueClick
            };
        },
        onCellValueChanged: (event) => {
            const { newValue, column, data } = event;
            updateValue(newValue, column.colId, data);
            dropdownValidator(event);
        },
        cellRendererSelector: (params) => {
            if(params.data.strategy === 'Total'){
                return totalRenderer
            } else {
                return {
                    component: ({ value, onClick }) => (<div style={{ width: '100%' }} onClick={onClick}>{ value }</div>),
                    params: {
                        onClick: () => handleValueClick(params.colDef, params.rowIndex, params.data),
                    }
                }
            }
        },
        editable: true,
        filter: false,
        width: 100,
        suppressMenu: true,
    });

    // Reintroduce once bugs are sorted
    const setQuarterYearLabel = (dateInvestment) => {
        if (!dateInvestment) return

        const qrt = dateInvestment.split(" ");
        
        if ( qrt[0] === 'Q1') {
            return qrt[1]
        } else if ( qrt[0] === 'Q2' ) {
            return `Q2-Q4 ${qrt[1]}`
        } else if ( qrt[0] === 'Q3' ) {
            return `Q3-Q4 ${qrt[1]}`
        } else {
            return `Q4 ${qrt[1]}`
        }
    }

    const validStartYear = fiscalYearPlusOne ? startYear + 1 : startYear;
    for (let i = validStartYear; i <= endYear; i++) {
        const year = i;
        const stringYear = year.toString();
        
        if (year <= endYear) {
            const yearDefinition = {
                headerName: stringYear,
                field: stringYear,
                cellStyle: getCommitScheduleStyling,
                type: 'numericColumn',
                cellEditor: 'numericEditor',
                cellEditorParams: () => {
                    // custom attribute for this grid only
                    const denyNegativeValues = true;
                    return {
                        field: stringYear,
                        updateValue,
                        denyNegativeValues
                    };
                },
                editable: (params) =>
                    params.node.rowPinned !== 'bottom' &&
                    targetExposureType !== 'noNewCommitments',
                onCellValueChanged: numericValidator,
                minWidth: 110,
                flex: 1,

                cellRendererSelector: (params) => {
                    if(params.data.strategy === 'Total'){
                        return totalRenderer
                    } else {
                        return {
                            component: targetExposureType === 'noNewCommitments' ? 'formatNumber' : 'formatNumberWithLock',
                            params: {
                                toggleLock,
                                optimizer,
                                handleValueClick
                            }
                        }
                    }
                },
            };

            tableDefinitions.push(yearDefinition);
        }
    }
    return tableDefinitions;
}

export function FrameworkComponents() {
    return {
        numericEditor: NumericEditor,
        formatNumberWithLock: FormatNumberWithLock,
        formatNumberWithoutLock: FormatNumberWithoutLock,
        formatPercentage: FormatPercentage,
        formatNumber: FormatNumber,
        totalsRowRenderer: TotalsRowRenderer,
    };
}
